import { PageTemplateMatcher, PricingPlan } from "./types";
import { SHINE_BASIC_PRICE, SHINE_PLUS_PRICE, SHINE_PRO_PRICE, SHINE_BUSINESS_PRICE, ALL_PATHS_MATCHERS, SUB_CAT_1, UNKNOWN, EMPTY } from "./constants";
import queryString from "query-string";

export const getSubtitleText = (tarifSubtitle: Gatsby.Maybe<Pick<Gatsby.PrismicStructuredTextType, "raw">>) => {
  if (!tarifSubtitle?.raw) {
    return undefined;
  }

  if (!Array.isArray(tarifSubtitle.raw)) {
    return undefined;
  }

  if (tarifSubtitle.raw.some((s: any) => !s?.text || typeof s?.text !== 'string')) {
    return undefined;
  }

  return tarifSubtitle.raw.map((s: { text: string }) => s.text).join(' ') ?? undefined;
}

export const getPricingPlanMonthDuration = (price?: number, title?: string) => {
  const allMonthlyPrices = [SHINE_BASIC_PRICE, SHINE_PLUS_PRICE, SHINE_PRO_PRICE, SHINE_BUSINESS_PRICE];
  if (price && allMonthlyPrices.includes(price)) {
    return 1;
  }

  if (title) {
    const matches = title.match(/(\d+) mois/i);

    if (matches) {
      return parseInt(matches[1], 10);
    }
  }

  return null;
};

export const getPricingPlanTax = (subtitle?: string) => {
  if (!subtitle) {
    return null;
  }

  if (subtitle.includes('HT')) {
    return 'HT';
  }

  if (subtitle.includes('TTC')) {
    return 'TTC';
  }

  return null;
};

export const getPricingPlanNewPricing = (location: Location, planName: string) => {
  const currentParams = queryString.parse(location?.search);
  const cycle = (currentParams["cycle"] as string) ?? "";
  const isMonthly = cycle === "mensuel";

  const isFree = planName.toLocaleLowerCase().includes('free');
  const isStart = planName.toLocaleLowerCase().includes('start');
  const isPlus = planName.toLocaleLowerCase().includes('plus');
  const isBusiness = planName.toLocaleLowerCase().includes('business');

  if (isFree) {
    return isMonthly ? 'free_monthly_2025' : 'error';
  }

  if (isStart) {
    return isMonthly ? 'start_monthly_2025' : 'start_yearly_2025';
  }

  if (isPlus) {
    return isMonthly ? 'plus_monthly_2025' : 'plus_yearly_2025';
  }

  if (isBusiness) {
    return isMonthly ? 'business_monthly_2025' : 'business_yearly_2025';
  }

  return 'error';
};

export const getPricingPlan = (price?: number, title?: string) => {
  switch (price) {
    case SHINE_BASIC_PRICE: return PricingPlan.SHINE_BASIC;
    case SHINE_PLUS_PRICE: return PricingPlan.SHINE_PLUS;
    case SHINE_PRO_PRICE: return PricingPlan.SHINE_PRO;
    case SHINE_BUSINESS_PRICE: return PricingPlan.SHINE_BUSINESS;
    default: {
      if (title) {
        const lowercaseTitle = title.toLowerCase();
        if (lowercaseTitle.includes('shine basic')) return PricingPlan.SHINE_BASIC;
        if (lowercaseTitle.includes('shine plus')) return PricingPlan.SHINE_PLUS;
        if (lowercaseTitle.includes('shine pro')) return PricingPlan.SHINE_PRO;
        if (lowercaseTitle.includes('shine business')) return PricingPlan.SHINE_BUSINESS;
      }

      return null;
    }
  }
};

export const isInternalLink = (link: string) => link?.startsWith('/');

export const getAbsoluteLink = (link: string) => {
  if (isInternalLink(link)) {
    return `${process.env.GATSBY_HOST}${link}`;
  }

  return link;
};

export const findPageTemplate = (pathname: string, matchers: PageTemplateMatcher[]) => {
  const match = matchers.find(({ paths }) => paths.some(path => pathname.startsWith(path)));
  const pageTemplate = match?.pageTemplate ?? UNKNOWN;

  return pageTemplate;
};

export const getPageTemplate = (pathname: string) => {
  if (!pathname) {
    return EMPTY;
  }

  if (pathname === '/') {
    return 'Homepage';
  }

  const pageTemplate = findPageTemplate(pathname, ALL_PATHS_MATCHERS);

  return pageTemplate;
};

export const getPageSubCat = (location: Location) => {
  const { pathname, search } = location;

  if (!pathname) {
    return null;
  }

  const category = SUB_CAT_1.get(pathname);

  if (!!category) {
    return category;
  }

  const isTarifs = pathname === '/tarifs/';

  if (!isTarifs) {
    return null;
  }

  const searchParams = new URLSearchParams(search);
  const section = searchParams.get('section');

  if (!section) {
    return 'Indépendant·es';
  }

  switch (section) {
    case 'independantes':
    default:
      return 'Indépendant·es';
    case 'tpe-et-startups':
      return 'TPE et Startups';
    case 'packs-creation-d-entreprise':
      return "Packs création d'entreprise";
    case 'creation-de-micro-entreprise':
      return 'Création de micro-entreprise';
    case 'creation-d-entreprise':
      return "Création d'entreprise";
    case 'depot-de-capital':
      return 'Dépôt de capital';
  }
};

export const buildPageProperties = (location: Location) => {
  const { href, pathname } = location;
  const url = new URL(href);

  const isTarifs = pathname === '/tarifs/';
  const hasSection = url.searchParams.has('section');
  const shouldIncludeVirtualPath = isTarifs && hasSection;
  const virtualPathObj = shouldIncludeVirtualPath
    ? { pageVirtPath: url.toString() }
    : {};

  return {
    pageTemplate: getPageTemplate(pathname),
    eventFunnelStep: 'Inscription',
    ...virtualPathObj
  }
};
