import { buildPageProperties, getAbsoluteLink, getPricingPlan, getPricingPlanNewPricing, getPricingPlanMonthDuration, getPricingPlanTax, isInternalLink } from "./properties";
import patchSignupURL from "src/atoms/PrismicLink/lib/patchSignupURL";
import { VisitorContextType } from "src/providers/VisitorContext/types";
import { ClickInfo, ClickInfoNewPricing } from "./types";
import { logEvent } from "../analytics";
import { navigate } from "gatsby";
import { getNewAccountEventName } from "./name";

/**
 * Prepares the appropriate structure for the Segment event to be sent
 * @see https://segment.com/docs/connections/spec/track/
 */
const buildNewAccountClickedEvent = (
  link: string,
  clickInfo: ClickInfo
) => {
  const {
    isReferralFlow: isReferralFlowTemp,
    isReferralValid,
    fromLocation,
    platform,
    label,
    price,
    title,
    block,
    subtitle
  } = clickInfo;

  const eventName = getNewAccountEventName(link, clickInfo);
  const hasPrice = typeof price !== 'undefined';
  const absoluteLink = getAbsoluteLink(link);
  const isReferralFlow = link.startsWith('/referral/') || isReferralFlowTemp;
  const plan = getPricingPlan(price, title);
  const months = getPricingPlanMonthDuration(price, title);
  const tax = getPricingPlanTax(subtitle);
  const propertiesBlock = block ? { block } : {};
  const propertiesCurrency = hasPrice ? { currency: 'EUR' } : {};
  const propertiesIsReferralFlow = isReferralFlow ? { isReferralFlow } : {};
  // When false, we still want to make it apparent
  const propertiesIsReferralValid = typeof isReferralValid === 'boolean' ?  { isReferralValid } : {};
  const propertiesLabel = label ? { label } : {};
  const propertiesMonths = months ? { months } : {};
  const propertiesPlan = plan ? { plan } : {};
  const propertiesPlatform = platform ? { platform } : {};
  const propertiesPrice = hasPrice ? { value: price } : {};
  const propertiesTax = tax ? { tax } : {};
  const propertiesOther = fromLocation ? buildPageProperties(fromLocation) : {};

  const properties = {
    destinationUrl: absoluteLink,
    ...propertiesBlock,
    ...propertiesCurrency,
    ...propertiesIsReferralFlow,
    ...propertiesIsReferralValid,
    ...propertiesLabel,
    ...propertiesMonths,
    ...propertiesPlan,
    ...propertiesPlatform,
    ...propertiesPrice,
    ...propertiesTax,
    ...propertiesOther
  };

  const event = { name: eventName, properties };

  return event;
}

/**
 * Prepares the appropriate structure for the Segment event to be sent
 * @see https://segment.com/docs/connections/spec/track/
 */
const buildNewAccountClickedEventNewPricing = (
  link: string,
  clickInfoNewPricing: ClickInfoNewPricing
) => {
  const {
    fromLocation,
    platform,
    label,
    block,
    plan: planName
  } = clickInfoNewPricing;

  const absoluteLink = getAbsoluteLink(link);
  const plan = getPricingPlanNewPricing(fromLocation, planName);
  const propertiesBlock = block ? { block } : {};
  const propertiesLabel = label ? { label } : {};
  const propertiesPlan = plan ? { plan } : {};
  const propertiesPlatform = platform ? { platform } : {};
  const propertiesPath = fromLocation?.pathname ? { path: fromLocation.pathname } : {};

  const properties = {
    destinationUrl: absoluteLink,
    ...propertiesBlock,
    ...propertiesLabel,
    ...propertiesPlan,
    ...propertiesPlatform,
    ...propertiesPath
  };

  const event = { name: 'Lead New Pricing Button Clicked', properties };

  return event;
}

const onClickNewAccountNewPricing = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  clickInfoNewPricing: ClickInfoNewPricing
) => {
  e.preventDefault();

  const link = clickInfoNewPricing.toUrl;
  const event = buildNewAccountClickedEventNewPricing(link, clickInfoNewPricing);
  const isClientSideRedirection = isInternalLink(link);

  // We enhance the user experience by navigating immediately
  if (isClientSideRedirection) {
    logEvent(event);
    navigate(link);
  } else {
    logEvent(event, () => navigate(link));
  }
}

const onClickNewAccount = (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  visitorContext: VisitorContextType,
  clickInfo: ClickInfo
) => {
  e.preventDefault();

  const link = patchSignupURL(visitorContext);
  const event = buildNewAccountClickedEvent(link, clickInfo);
  const isClientSideRedirection = isInternalLink(link);

  // We enhance the user experience by navigating immediately
  if (isClientSideRedirection) {
    logEvent(event);
    navigate(link);
  } else {
    logEvent(event, () => navigate(link));
  }
}

const hasChangedLocation = (prevLocation: Location | null, location: Location) => {
  const SEARCH_PARAM_CYCLE = 'cycle';
  const SEARCH_PARAM_OFFER = 'offre';
  const SEARCH_PARAM_SECTION = 'section';

  const { pathname: prevPathname } = prevLocation || {};
  const { pathname, search } = location;

  const prevSearchParams = new URLSearchParams((prevLocation || {}).search);
  const prevCycle = prevSearchParams.get(SEARCH_PARAM_CYCLE);
  const prevOffer = prevSearchParams.get(SEARCH_PARAM_OFFER);
  const prevSection = prevSearchParams.get(SEARCH_PARAM_SECTION);

  // Gatsby relies on pathname changes by default so we need to override the logic
  // We do so by adding an allowlist of query params
  const searchParams = new URLSearchParams(search);
  const cycle = searchParams.get(SEARCH_PARAM_CYCLE);
  const offer = searchParams.get(SEARCH_PARAM_OFFER);
  const section = searchParams.get(SEARCH_PARAM_SECTION);

  const isDifferentPathname = prevPathname !== pathname;
  const isDifferentCycle = prevCycle !== cycle;
  const isDifferentOffer = prevOffer !== offer;
  const isDifferentSection = prevSection !== section;

  // For instance, /tarifs/ page has different content based on `section` query param
  const isDifferent = isDifferentPathname || isDifferentCycle || isDifferentOffer || isDifferentSection;

  return isDifferent;
};

export { buildNewAccountClickedEvent, hasChangedLocation, onClickNewAccount, onClickNewAccountNewPricing };
